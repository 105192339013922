import React from "react";
import {
  FormControl,
  FormLabel,
  Select,
  FormErrorMessage,
  Text,
  Input,
} from "@chakra-ui/react";
import { Controller } from "react-hook-form";

export const CustomFormControl = ({
  controlId,
  label,
  placeholder,
  register,
  errors,
  options,
  setServiceMode,
}) => {
  return (
    <FormControl isInvalid={errors[controlId]}>
      <FormLabel htmlFor={controlId}>
        {label}
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Select
        id={controlId}
        placeholder={placeholder}
        {...register(controlId, {
          required: "This is required",
        })}
        onChange={(e) => setServiceMode(e.target.value)}
        color={"black"}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
      <FormErrorMessage>
        {errors[controlId] && errors[controlId].message}
      </FormErrorMessage>
    </FormControl>
  );
};

export const B2cCalculatorFormControl = ({
  controlId,
  label,
  placeholder,
  register,
  errors,
  options,
}) => {
  return (
    <FormControl isInvalid={errors[controlId]}>
      <FormLabel htmlFor={controlId}>
        {label}
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Select
        id={controlId}
        placeholder={placeholder}
        {...register(controlId, {
          required: "This is required",
        })}
        color={"black"}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
      <FormErrorMessage>
        {errors[controlId] && errors[controlId].message}
      </FormErrorMessage>
    </FormControl>
  );
};

export const ServiceTypeFormControl = ({
  controlId,
  label,
  placeholder,
  register,
  errors,
  options,
}) => {
  return (
    <FormControl isInvalid={errors[controlId]}>
      <FormLabel htmlFor={controlId}>
        {label}
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Select
        id={controlId}
        placeholder={placeholder}
        {...register(controlId, {
          required: "This is required",
        })}
        color={"black"}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
      <FormErrorMessage>
        {errors[controlId] && errors[controlId].message}
      </FormErrorMessage>
    </FormControl>
  );
};

export const CalculatorWeightFormControl = ({ register, errors, ...props }) => {
  return (
    <FormControl isInvalid={errors.weight}>
      <FormLabel htmlFor="Weight">
        Total Weight (Kg)
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Input
        {...props}
        id="weight"
        type="number"
        step="any"
        placeholder="Weight (0.4 kg) = 400gm"
        {...register("weight", {
          required: "This is required",
        })}
      />
      <FormErrorMessage>{errors?.weight?.message}</FormErrorMessage>
    </FormControl>
  );
};

export const CODAmountControl = ({ controlId, label, control, errors }) => {
  return (
    <FormControl isInvalid={errors[controlId]}>
      <FormLabel htmlFor={controlId}>
        {label}
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Controller
        name={controlId}
        control={control}
        defaultValue=""
        rules={{ required: "This is required" }}
        render={({ field }) => (
          <Input id={controlId} type="number" placeholder={label} {...field} />
        )}
      />
      <FormErrorMessage>
        {errors[controlId] && errors[controlId].message}
      </FormErrorMessage>
    </FormControl>
  );
};

export const CustomPincodeFormControl = ({
  controlId,
  label,
  placeholder,
  register,
  errors,
}) => {
  return (
    <FormControl isInvalid={errors[controlId]}>
      <FormLabel htmlFor={controlId}>
        {label}{" "}
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Input
        id={controlId}
        placeholder={placeholder}
        {...register(controlId, {
          required: "This is required",
          pattern: {
            value: /^[1-9][0-9]{5}$/,
            message: "Wrong pincode",
          },
        })}
      />
      <FormErrorMessage>
        {errors[controlId] && errors[controlId].message}
      </FormErrorMessage>
    </FormControl>
  );
};

export const CustomDeliveryPincodeFormControl = ({
  controlId,
  label,
  placeholder,
  register,
  errors,
}) => {
  return (
    <FormControl isInvalid={errors[controlId]}>
      <FormLabel htmlFor={controlId}>
        {label}{" "}
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Input
        id={controlId}
        placeholder={placeholder}
        {...register(controlId, {
          required: "This is required",
          pattern: {
            value: /^[1-9][0-9]{5}$/,
            message: "Wrong pincode",
          },
        })}
      />
      <FormErrorMessage>
        {errors[controlId] && errors[controlId].message}
      </FormErrorMessage>
    </FormControl>
  );
};

export const PaymentModeControl = ({ controlId, label, control, errors }) => {
  return (
    <FormControl isInvalid={errors[controlId]}>
      <FormLabel htmlFor={controlId}>
        {label}
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Controller
        name={controlId}
        control={control}
        defaultValue=""
        rules={{ required: "This is required" }}
        render={({ field }) => (
          <Select
            id={controlId}
            placeholder={`Select ${label}`}
            onChange={(e) => {
              field.onChange(e);
            }}
            {...field}
          >
            <option value="Prepaid">Prepaid</option>
            <option value="COD">COD</option>
            {/* {isToPay && <option value="ToPay">ToPay</option>} */}
          </Select>
        )}
      />
      <FormErrorMessage>
        {errors[controlId] && errors[controlId].message}
      </FormErrorMessage>
    </FormControl>
  );
};
